<template>
  <v-app>

  <core-view />

  <!-- Start application's alerts -->
    <v-snackbar
      v-model="snackbar"
      :color="color"
    >
      <p  
        v-html="text"
        class="app_alert_text"
      />
      <v-btn
        :color="btn_text_color"
        text
        @click="snackbar = false"
        v-html="alert_close_text"
      />
    </v-snackbar>
    <!-- End application's alerts -->
  </v-app>
</template>

<script>
  /**
   * 
   * General documentation: 
   * alerts: this.$store.commit('toggle_alert', {color: '', text: ''})
   * 
   */
  export default {
    data: () => ({
      snackbar: false,
      btn_text_color: 'white',
      alert_close_text: 'Cerrar'
    }),
    watch: {
      // set snackbar alert to toggled
      alert () {
        this.snackbar = this.alert
      },
      // set VueX state:alert to the current value
      snackbar () {
        this.$store.commit('set_alert', this.snackbar)
      },
      login_status: function () {
        if (this.login_status) {
          this.$router.push('/admin/projects')
        } else {
          this.$router.push('/')
        }
      }
    },
    components: {
      CoreAppBar: () => import('@/components/core/AppBar'),
      CoreFooter: () => import('@/components/core/Footer'),
      CoreFab: () => import('@/components/core/Fab'),
      CoreView: () => import('@/components/core/View')
    },
    computed: {
      login_status () {
        return this.$store.state.login_in_status
      },
      token () {
        return this.$store.state.token
      },
      alert () {
        return this.$store.state.alert
      },
      text () {
        return this.$store.state.text
      },
      color () {
        return this.$store.state.color
      }
    },
    beforeMount () {
      if (this.token !== '') {
        this.$store.commit('toggle_auth', true)
      }
    }
  }
</script>

<style lang="sass">
  section
    padding: 64px 16px

    @media screen and (max-width: 640px)
      padding: 16px
</style>
<style>
.section_titles {
  font-family: 'Open sans';
  font-size: 25px;
  font-weight: bold
}
.app_alert_text {
  margin: 10px;
  font-family: 'Open sans', sans-serif
}
.v-btn {
  text-transform: none
}
</style>
