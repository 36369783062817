import Vue from 'vue'
import './plugins/components'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// import apexcharts from 'vue-apexcharts'
import load from 'lodash'
import encode from './json_encoder.js'
import loadinganimation from '@/components/core/CoreLoading'


Vue.config.productionTip = false

window.uri = 'https://api.martel.com.mx' // 'http://ec2-52-14-35-116.us-east-2.compute.amazonaws.com'
window.db = axios
window._ = load
window.encode = encode

// Vue.component('chart', apexcharts)
Vue.component('loading-animation', loadinganimation)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')