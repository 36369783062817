import { set, toggle } from '@/utils/vuex'
import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: null,
    login_in_status: false,
    text: null,
    color: null,
    alert: false,
    token: localStorage.getItem('user-token') || ''
  },
  mutations: {
    toggle_alert (state, alert) {
      state.text = alert.text
      state.color = alert.color
      state.alert = true
    },
    toggle_auth (state, value) {
      state.login_in_status = value
    },
    set_alert (state, value) {
      state.alert = value
    },
    auth_error (err) {
      localStorage.removeItem('user-token')
    },
    auth_success (state, token) {
      localStorage.setItem('user-token', token)
      state.token = token
    },
  },
  actions: {
    login({commit}, user) {
      const uri = 'https://api.martel.com.mx' //'http://ec2-52-14-35-116.us-east-2.compute.amazonaws.com'
      return new Promise((resolve, reject) => {
        var body = new URLSearchParams()
        body.append('email', user.sEmail)
        body.append('password', user.sPassword)
        Axios.post(`${uri}/api/v1/auth`, body)
        .then(resp => {
          const token = resp.data.token
          commit('auth_success', token, user);
          commit('toggle_auth', true)
          resolve(resp)
        })
        .catch(err => {
          localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
          commit('auth_error', err);
          reject(err)
        })
      }) 
    },
    logout ({commit}) {
      return new Promise((resolve, reject) => {
        try {
          localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
          localStorage.removeItem('user-session')
          commit('toggle_auth', false)
          resolve({color: 'success', text: 'Sesión terminada'})
        } catch (e) {
          reject({color: 'fail',  text: 'Hubo un error terminando la sesíon, favor de intentar de nuevo.'})
        }
      }) 
    }
  }
})
