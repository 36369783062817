import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home'),
    },
    {
      path: '/admin',
      name: 'Admin',
      component: () => import('@/views/Admin'),
      meta: { 
        requiresAuth: true 
      },
      children: [
        {
          path: 'about',
          name: 'About',
          component: () => import('@/views/admin/About'),
          meta: { 
            requiresAuth: true 
          }
        },
        {
          path: 'general',
          name: 'General',
          component: () => import('@/views/admin/General'),
          meta: { 
            requiresAuth: true 
          }
        },        
        {
          path: 'projects',
          name: 'Projects',
          component: () => import('@/views/admin/Projects'),
          meta: { 
            requiresAuth: true 
          }
        },
        {
          path: 'contacts',
          name: 'Contact',
          component: () => import('@/views/admin/Contact'),
          meta: { 
            requiresAuth: true 
          }
        },
        {
          path: '*',
          name: 'Error',
          component: () => import('@/views/Error'),
          meta: { 
            requiresAuth: true 
          }
        },
      ]
    },
    {
      path: '*',
      name: 'Error',
      component: () => import('@/views/Error'),
    },
  ]
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.login_in_status) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else if (!(to.matched.some(record => record.meta.requiresAuth))) {
    if (store.state.login_in_status) {
      next({
        path: '/admin/applications'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  Vue.use(require('vue-analytics').default, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== 'development'
    }
  })
}

export default router
